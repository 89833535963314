import { render, staticRenderFns } from "./LoginForm.vue?vue&type=template&id=bb09b4d0&scoped=true&"
import script from "./LoginForm.vue?vue&type=script&lang=js&"
export * from "./LoginForm.vue?vue&type=script&lang=js&"
import style0 from "./LoginForm.vue?vue&type=style&index=0&id=bb09b4d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb09b4d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pbk/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bb09b4d0')) {
      api.createRecord('bb09b4d0', component.options)
    } else {
      api.reload('bb09b4d0', component.options)
    }
    module.hot.accept("./LoginForm.vue?vue&type=template&id=bb09b4d0&scoped=true&", function () {
      api.rerender('bb09b4d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/authentication/src/components/Forms/LoginForm.vue"
export default component.exports